import React, { useContext, useEffect, useRef } from "react";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { 
  StyledTechCarouselContainer,
  StyledTechCarouselItem,
  StyledSwiperBullets,
  StyledImageWrapper,
} from "./style";
import Swiper, { Autoplay, Pagination } from "swiper";

const ImagesCarouselImage = (image) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find((e) => e.path === image.image);
  //console.log(image);
  return (
    <StyledTechCarouselItem className="swiper-slide">
      {imageData && (
        <StyledImageWrapper>
          <GatsbyImage
            image={getImage(imageData)}
            alt={imageData.alt}
            style = {{ "position": "unset" }}
          >
          </GatsbyImage>
        </StyledImageWrapper>
      )}
    </StyledTechCarouselItem>
  );
};

Swiper.use([Pagination, Autoplay]);

const ImagesCarousel = ({
  images,
}) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: true,
      spaceBetween: 50,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
      pagination: {
        el: sliderPaginationEl.current,
        clickable: true,
      },
    });
  }, [sliderEl]);
  return (
    <StyledTechCarouselContainer id="ImagesCarousel">
      <div ref={sliderEl} className="swiper-container">
        <div className="swiper-wrapper">
          {images.map((image, index) => (
            <ImagesCarouselImage
              key={index} image={image}
            />
          ))}
        </div>
        <StyledSwiperBullets ref={sliderPaginationEl} />
      </div>
    </StyledTechCarouselContainer>
  );
};

export default ImagesCarousel;
